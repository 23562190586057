/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../generated/graphql-types";
import {
  buildTextField,
  Expander,
  Form,
  Hero,
  InfographicIntroduction,
  Quote,
  SectorLeadership,
  SimpleTextBlock,
  StatisticDetails,
  Statistics,
  Stories,
} from "../components";
import { Logo } from "../components/Logo";
import { LogoDes } from "../components/LogoTitleDescrption";
import { getProfileInformation, getSuccess } from "../utils";
import { buildMenu, Layout } from "../layouts";
import { getCleanedRTF } from "@bond-london/graphcms-rich-text";
import {
  getAlt,
  getImage,
  getVideo,
} from "@bond-london/gatsby-graphcms-components";
import { Researchblock } from "../components/DownloadBlock";

const component: React.FC<
  PageProps<Pick<Query, "graphCmsSectorPage" | "allGraphCmsMedia">>
> = ({
  path,
  data: {
    graphCmsSectorPage: sectorPage,
    allGraphCmsMedia: { nodes },
  },
}) => {
  if (!sectorPage) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage.menu)}
      footerMenu={buildMenu(sectorPage.footerMenu)}
      keywords={sectorPage.keywords}
      title={sectorPage.pageTitle || sectorPage.sector}
      description={sectorPage.description}
    >
      <Hero
        title={sectorPage.sector}
        video={getVideo(sectorPage.heroVideo)}
        image={getImage(sectorPage.heroImage)}
        short={true}
        treatment={sectorPage.heroTreatment}
        showSearch={true}
      />
      <Expander
        paragraphsRTF={getCleanedRTF(sectorPage.statement)}
        introduction={sectorPage.businessChallengesIntroduction}
        callouts={sectorPage.businessChallenges.map((c) => ({
          title: c.headline,
          contentRTF: getCleanedRTF(c.content),
        }))}
      />
      <Quote
        quote={sectorPage.quote}
        profile={getProfileInformation(sectorPage.quoteFrom)}
      />
      <InfographicIntroduction
        introduction={getCleanedRTF(sectorPage.infographicIntroduction)}
        details={getCleanedRTF(sectorPage.infographicDetails)}
      />
      <SimpleTextBlock
        contentRTF={getCleanedRTF(sectorPage.genericInfographicText)}
      />
      <Statistics
        title={sectorPage.keyStatistic}
        statistics={sectorPage.statisticDetails as StatisticDetails[]}
      />
      {/* {nodes.map((n) => (
        // eslint-disable-next-line react/jsx-key
        <Logo
          name={n.channelName}
          ImageUrl={n.mediaLogo.url}
          medieaLink={n.mediaLink}
        />
      ))} */}
      {sectorPage.pageTitle == "Energy Transition Consulting" ? (
        <LogoDes name="" />
      ) : (
        ""
      )}
      {sectorPage.pageTitle == "Energy Transition Consulting" ? (
        <Logo name="" nodes={nodes} />
      ) : (
        ""
      )}
      {/* {sectorPage.pageTitle == "Energy Transition Consulting" ? ( */}
      {sectorPage?.addOn == "Yes" ? (
        <Researchblock
          quote={sectorPage?.insightContent}
          Link={"/en/research/" + sectorPage?.ctaUrl}
          Title={sectorPage?.insightHeading}
          CTA="Insights"
          ctaText={sectorPage?.ctaText}
        />
      ) : (
        ""
      )}
      {/* {sectorPage.pageTitle == "Oil and Gas Consulting" ? (
        <Researchblock
          quote="A collection of analysis, research and stories about our capabilities from our Energy Transition Experts."
          Link="/energy-transition-research"
          Title="Insights"
          CTA="Insights"
        />
      ) : (
        ""
      )}
      {sectorPage.pageTitle == "Public Sector Consulting" ? (
        <Researchblock
          quote="A collection of analysis, research and stories about our capabilities from our Energy Transition Experts."
          Link="/energy-transition-research"
          Title="Insights"
          CTA="Insights"
        />
      ) : (
        ""
      )} */}
      {/* {sectorPage.pageTitle == "Capital Projects Management and Consulting " ? (
        <Researchblock
          quote="A collection of analysis, research and stories about our capabilities from our Capital Project Experts."
          Link="/capital-projects-research"
          Title="Insights"
          CTA="Insights"
        />
      ) : (
        ""
      )} */}
      {console.log(sectorPage.successes)}
      {!!sectorPage.successes?.length && (
        <Stories
          title={sectorPage.storiesTitle}
          image={getImage(sectorPage.storiesImage)}
          alt={getAlt(sectorPage.storiesImage, "Success")}
          stories={sectorPage.successes.map(getSuccess)}
        />
      )}
      <SectorLeadership
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profiles={sectorPage.leadership.map((p) => getProfileInformation(p)!)}
        title={sectorPage.leadershipTitle}
        offset={true}
      />
      {sectorPage.form && (
        <Form
          title={sectorPage.form.optionalTitle}
          postUrl={sectorPage.form.postUrl}
          buttonText={sectorPage.form.buttonText}
          inputs={sectorPage.form.formFields.map(buildTextField)}
          thankYouText={sectorPage.form.thankYouMessage}
          errorText={sectorPage.form.submissionErrorMessage}
        />
      )}
    </Layout>
  );
};

export default component;

export const sectorPageQuery = graphql`
  query SectorPageQuery($id: String) {
    graphCmsSectorPage(id: { eq: $id }) {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      addOn
      ctaText
      insightHeading
      insightContent
      ctaUrl
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
    allGraphCmsMedia {
      nodes {
        mediaLink
        channelName
        mediaLogo {
          url
        }
      }
    }
  }
`;
