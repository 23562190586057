/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import leftIcon from "../images/Left Button.svg";
import rightIcon from "../images/Right Button.svg";
import { createScroller } from "./Scroller";
interface Props {
  name?: string;
  className?: string;
  ImageUrl?: string;
  medieaLink?: string;
  nodes?: object;
}
export const Logo: React.FC<Props> = ({ className, nodes }) => {
  const sectionRef = useRef<HTMLElement>(null);
  useEffect(() => {
    const ref = sectionRef.current;
    if (ref) {
      const state = createScroller(ref);
      return state.destroy;
    }
  }, []);
  return (
    <section
      data-component="LOGO"
      className={classNames(
        "relative w-full page-grid max-width z-10 ",
        className ? className : "py-section"
      )}
      ref={sectionRef}
    >
      <button className="prev-button col-start-2  lg:col-start-1 lg:col-span-1 h-full justify-self-start lg:justify-self-center">
        <img src={leftIcon} alt="Left" />
      </button>
      <div className="viewport col-start-1 col-span-14 md:col-start-2 md:col-span-12  sm:col-span-6 relative">
        <div className="scroll-container relative top-0 left-0 bottom-0 right-0 w-full flex grid-gap overflow-scroll hide-scrollbar sm:col-span-6">
          {/* <div className="w-mobile-6-cols md:w-tablet-4-cols lg:w-desktop-3-cols relative flex-grow-0 flex-shrink-0 sm:col-span-6">
            <a href={medieaLink} target="_blank" rel="noopener noreferrer ">
              <img
                src={ImageUrl}
                alt="Logo"
                className="ml-auto ga-ariticle-image"
              />
            </a>
          </div> */}
          {nodes.map((n) => (
            <div className="w-mobile-6-cols md:w-tablet-4-cols lg:w-desktop-3-cols relative flex-grow-0 flex-shrink-0 sm:col-span-6">
              <a href={n.mediaLink} target="_blank" rel="noopener noreferrer ">
                <img
                  src={n.mediaLogo.url}
                  alt="Logo"
                  className="ml-auto ga-ariticle-image"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      {/*row-start-3 lg:row-start-2  */}
      <button className="next-button col-start-13  lg:col-start-14 lg:col-span-1 h-full justify-self-end lg:justify-self-center">
        <img src={rightIcon} alt="Right" />
      </button>
    </section>
  );
};
function n(n: any) {
  throw new Error("Function not implemented.");
}
